.bg_table {
  background-color: #656565 !important;
  color: white;
}
td {
  border: 0px !important;
}
tr:nth-child(even) {
  background-color: #edf2f7;
}

.list_header {
  background-color: #f2f2f2;
}

.list_header--bottom {
  background-color: #f2f2f2;
  border-bottom: 2px solid #d6d6d6;
}
.list_content {
  padding: 0rem 10rem;
}

.w-40 {
  width: 30%;
}
.cursor {
  cursor: pointer !important;
}

.invoice-logo-side {
  width: 7%;
  background-image: url(../../../assets/images/invoice/Path48.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.invoice-logo-main {
  width: 25%;
  background-image: url(../../../assets/images/invoice/logo.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.invoice-logo-footer {
  width: 14%;
  background-image: url(../../../assets/images/invoice/Group76.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.invoice-holder {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.invoice-container {
  width: 100% !important; /** 1320px*/
}
.footer-invoice {
  font-size: 12px;
}
.text-invoice {
  color: #04337f;
}

.bg-invoice {
  background-color: #efefef !important;
}

.bg-table_invoice {
  background-color: #000000 !important;
  -webkit-print-color-adjust: exact !important;
  color: white;
}

.bg-table_disable{
  background-color: #d9d9d9 !important;
  -webkit-print-color-adjust: exact !important;
  color: rgb(0, 0, 0);
}

.td-invoice {
  td {
    border: solid 1px #e0e0e0 !important;
    background-color: white;
  }
}
.td-invoice-extra {
height: 46px;
background-color: white;
}
tr.td-invoice-extra{
  background-color: white;
}

.procedures-invoice {
  text-align: center !important;
  vertical-align: middle !important;
  background-color: #e0e0e0;
}
.bg-procedures_invoice {
  background-color: #e0e0e0 !important;
}
.icons-invoice {
  width: 35px;
}

.footer-width {
  width: 60%;
}

.sadd_bank{
  border-bottom: 1px solid #000000 !important;
}

.lated-pilgrams{
  border: 1px solid #dc3545;
}
